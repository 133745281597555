'use client'

import { PageHeader } from '@brand/slots/page-header/page-header'
import { ErrorPageUI } from './error-page-ui'
import styles from './error-page.module.css'

interface ErrorPageProps {
  statusCode: number
  header?: string
  message: string
}

/**
 * `Error` component used for handling errors.
 * https://nextjs.org/docs/advanced-features/custom-error-page
 */
export function ErrorPage({
  statusCode,
  header = 'Oops!',
  message,
}: ErrorPageProps) {
  return (
    <>
      <PageHeader />
      <main className={styles.errorPage}>
        <ErrorPageUI
          statusCode={statusCode}
          header={header}
          message={message}
          listings={[]}
        />
      </main>
    </>
  )
}
