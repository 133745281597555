import clsx from 'clsx'
import type { ComponentProps } from 'react'
import { AnchorListDisclosure } from '../../../components/anchor-list-disclosure/anchor-list-disclosure'
import type { Anchor } from '../../../components/anchor/anchor'
import { Heading } from '../../../components/heading/heading'
import type { HomePageContent } from '../../home/home-page.types'
import styles from './popular-cities-links.module.css'

type PopularCityLinksProps = {
  apartmentLinks: [string, string][]
  houseLinks?: [string, string][]
  content?: HomePageContent
} & Partial<
  Pick<
    ComponentProps<typeof AnchorListDisclosure>,
    'collapseLimit' | 'styles'
  > &
    Partial<Pick<ComponentProps<typeof Anchor>, 'variant'>>
>

export function PopularCitiesLinks(props: PopularCityLinksProps) {
  const apartmentLinks = props.apartmentLinks
  const houseLinks = props.houseLinks || []
  const showHouseLinks = houseLinks.length > 0

  if (apartmentLinks.length === 0 && houseLinks.length === 0) {
    return null
  }

  const anchorListStyles = {
    list: styles.popularCitiesSectionList,
    link: styles.popularCitiesSectionLink,
    button: styles.popularCitiesSectionLink,
    ...props.styles,
  }

  return (
    <div
      className={clsx(
        styles.popularCityLinksWrapper,
        !showHouseLinks && styles.popularCityLinksWrapperNoHouses
      )}
    >
      <div className={styles.popularCityLinksGroup} role="group">
        <Heading as="h3">
          {props.content?.popularCitiesApartmentsTitle ?? 'Apartments'}
        </Heading>

        <AnchorListDisclosure
          styles={anchorListStyles}
          collapseLimit={props.collapseLimit || 8}
          tagPrefixFallback="apartments"
          links={apartmentLinks}
          variant={props.variant}
          tagSection={'popular_cities_section'}
        />
      </div>

      {showHouseLinks && (
        <div className={styles.linksRight} role="group">
          <Heading as="h3">
            {props.content?.popularCitiesHousesTitle ?? 'Houses'}
          </Heading>

          <AnchorListDisclosure
            styles={anchorListStyles}
            collapseLimit={props.collapseLimit || 8}
            tagPrefixFallback="houses"
            links={houseLinks}
            variant={props.variant}
            tagSection={'popular_cities_section'}
          />
        </div>
      )}
    </div>
  )
}
