import clsx from 'clsx'
import styles from './logo.module.css'

type LogoProps = {
  className?: string
  colorScheme?: 'dark'
  width?: number
  height?: number
}

export function Logo({ className, colorScheme, width, height }: LogoProps) {
  return (
    <svg
      viewBox="0 0 162 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={clsx(styles.logo, className)}
      width={width}
      height={height}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.90545 0.692291L6.10352e-05 12.9877H4.39291L4.90545 11.3649H9.37441L9.9058 12.9877H14.3748L9.46938 0.692291H4.90545ZM7.14971 4.39752L8.59444 8.80406H5.76154L7.14971 4.39752Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.5008 4.03954V5.37154H18.5392C19.0908 4.27839 20.5167 3.78339 21.6577 3.78339C24.6631 3.78339 26.1071 5.98631 26.1071 8.44539C26.1071 11.963 23.6736 13.2438 21.4482 13.2438C20.3645 13.2438 19.5664 12.8505 18.9009 12.0994V16.0262H14.9459V4.03954H18.5008ZM22.1521 8.49662C22.1521 7.07877 21.4685 6.41346 20.4217 6.41346C19.4707 6.41346 18.8248 7.23316 18.8248 8.42808C18.8248 9.67492 19.4134 10.5285 20.4413 10.5285C21.4873 10.5285 22.1521 9.72616 22.1521 8.49662Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M34.0934 12.0317L34.2834 12.9877H38.3718V12.8167C37.8571 12.4415 37.839 12.1847 37.839 11.6724V7.48943C37.839 5.35435 37.5534 3.78351 32.8379 3.78351C28.9018 3.78351 27.3998 4.89328 27.3998 6.78882H31.2026C31.2982 6.02035 32.0391 5.91789 32.59 5.91789C33.009 5.91789 33.884 6.00374 33.884 6.65243C33.884 7.30644 32.8005 7.42309 31.4805 7.56521C29.4233 7.7867 26.7916 8.07005 26.7916 10.546C26.7916 12.4069 28.4646 13.2439 30.3472 13.2439C31.8303 13.2439 32.9706 12.9704 34.0934 12.0317ZM32.4964 9.40067C32.951 9.31283 33.4418 9.21799 33.884 9.02566C33.9789 10.2545 33.5215 11.2286 32.1348 11.2286C31.3548 11.2286 30.8793 10.9212 30.8793 10.4774C30.8793 9.71315 31.6206 9.5699 32.4964 9.40067Z"
        fill="currentColor"
      />
      <path
        d="M39.2481 4.03917H42.8421V5.84956H42.8798C43.4315 4.44902 44.4014 3.78302 45.9411 3.78302C46.0562 3.78302 46.1706 3.79825 46.2853 3.8135C46.3418 3.82102 46.3983 3.82854 46.455 3.83425V7.38648C46.3681 7.37543 46.2793 7.36071 46.1898 7.34587C46.0047 7.31518 45.8167 7.28402 45.6373 7.28402C44.0208 7.28402 43.2031 7.96664 43.2031 9.89679V12.9873H39.2481V4.03917Z"
        fill="currentColor"
      />
      <path
        d="M48.491 9.75653C48.491 12.9162 49.1146 13.2478 54.2769 12.9854V10.3851H53.652C52.5557 10.3851 52.4237 10.0888 52.4237 9.25114V5.93429H54.3153V3.84076H52.4237V0.996063H48.5483V3.84076H47.0923V5.93429H48.491V9.75653Z"
        fill="currentColor"
      />
      <path
        d="M104.91 12.9854C99.7481 13.2478 99.1238 12.9162 99.1238 9.75653V5.93429H97.7252V3.84076H99.1811V0.996063H103.056V3.84076H104.948V5.93429H103.056V9.25114C103.056 10.0888 103.189 10.3851 104.285 10.3851H104.91V12.9854Z"
        fill="currentColor"
      />
      <path
        d="M59.17 4.03917H55.5997V12.9873H59.4877V8.56479C59.4877 7.48894 59.7118 6.5841 60.8514 6.5841C61.88 6.5841 62.1607 7.11302 62.1607 8.4284V12.9873H66.048V8.56479C66.048 7.48894 66.2728 6.5841 67.4124 6.5841C68.441 6.5841 68.7203 7.11302 68.7203 8.4284V12.9873H72.6083V7.69387C72.6083 5.2521 71.711 3.78302 68.8522 3.78302C67.4124 3.78302 66.3098 4.34656 65.4691 5.4224C65.002 4.46633 64.0293 3.78302 62.2724 3.78302C60.8717 3.78302 59.6567 4.68856 59.17 5.37117V4.03917Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M77.7023 9.31651C77.7023 10.3751 78.61 11.075 79.6498 11.075C80.2733 11.075 80.841 10.8188 81.1245 10.2892H84.8875C84.206 12.5087 81.7111 13.244 79.4033 13.244C76.2275 13.244 73.7696 11.6039 73.7696 8.59928C73.7696 5.66182 76.2087 3.7829 79.3851 3.7829C83.0532 3.7829 85.2653 6.07167 85.2087 9.31651H77.7023ZM81.2761 7.50613C81.2761 6.68644 80.4633 5.9519 79.5367 5.9519C78.3643 5.9519 77.7023 6.54936 77.7023 7.50613H81.2761Z"
        fill="currentColor"
      />
      <path
        d="M89.8229 4.03917H86.2296V12.9873H90.1622V8.4284C90.1622 7.28402 90.4834 6.5841 91.6936 6.5841C92.8094 6.5841 93.0741 7.36917 93.0741 8.2574V12.9873H97.0074V7.62533C97.0074 5.16625 95.7778 3.78302 93.169 3.78302C91.6747 3.78302 90.7104 4.36387 89.8229 5.43971V4.03917Z"
        fill="currentColor"
      />
      {/* G U I D E */}
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M111.115 14.6546C110.918 14.5064 110.831 14.3409 110.831 14.2302H106.256C106.476 15.6515 108.182 17.0167 112.231 17.0167C117.878 17.0167 118.272 14.7473 118.272 11.85V4.06364H114.266V5.33679C113.458 4.39594 112.166 3.78671 110.787 3.78671C107.022 3.78671 105.666 6.31502 105.666 8.69517C105.666 11.1674 107.482 13.2706 110.655 13.2706C112.297 13.2706 113.282 12.7729 113.938 12.219V13.0124C113.938 14.3963 113.413 14.8948 112.1 14.8948C111.64 14.8948 111.312 14.802 111.115 14.6546ZM112.012 10.6143C110.787 10.5956 110.218 9.6914 110.218 8.73187C110.218 7.4774 110.635 6.51787 112.012 6.53587C113.458 6.53587 113.938 7.55079 113.938 8.6211C113.938 9.65471 113.413 10.6696 112.012 10.6143Z"
        fill={colorScheme === 'dark' ? 'currentColor' : '#B01'}
      />
      <path
        d="M126.348 12.9916H129.956V4.04279H126.067V8.34617C126.067 9.7294 125.712 10.4979 124.59 10.4979C123.656 10.4979 123.189 10.1053 123.189 8.87579V4.04279H119.3V9.49055C119.3 12.0521 120.683 13.247 122.814 13.247C124.422 13.247 125.376 12.9064 126.348 11.6769V12.9916Z"
        fill={colorScheme === 'dark' ? 'currentColor' : '#B01'}
      />
      <path
        d="M131.095 12.9918H134.984V4.04306H131.095V12.9918Z"
        fill={colorScheme === 'dark' ? 'currentColor' : '#B01'}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M143.303 12.9916V11.7953C142.387 12.8885 141.545 13.2471 140.293 13.2471C137.34 13.2471 135.844 11.2152 135.844 8.68756C135.844 5.71617 137.657 3.78602 139.994 3.78602C141.228 3.78602 142.088 4.06017 142.91 4.94771V0.695557H146.799V12.9916H143.303ZM143.041 8.6024C143.041 7.61171 142.667 6.53586 141.377 6.53586C140.088 6.53586 139.732 7.66363 139.732 8.65363C139.732 9.59309 139.994 10.6856 141.265 10.6856C142.611 10.6856 143.041 9.64433 143.041 8.6024Z"
        fill={colorScheme === 'dark' ? 'currentColor' : '#B01'}
      />
      <path
        d="M134.984 3.12851H131.095V0.995514H134.984V3.12851Z"
        fill={colorScheme === 'dark' ? 'currentColor' : '#B01'}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M153.154 11.0784C152.126 11.0784 151.228 10.3784 151.228 9.3199H158.651C158.705 6.07505 156.519 3.78629 152.892 3.78629C149.752 3.78629 147.341 5.66521 147.341 8.60267C147.341 11.608 149.771 13.2474 152.911 13.2474C155.192 13.2474 157.659 12.5135 158.332 10.2926H154.613C154.332 10.8222 153.771 11.0784 153.154 11.0784ZM153.042 5.95598C153.958 5.95598 154.762 6.68982 154.762 7.50952H151.228C151.228 6.55275 151.883 5.95598 153.042 5.95598Z"
        fill={colorScheme === 'dark' ? 'currentColor' : '#B01'}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M159.876 4.45299H160.02C160.197 4.45299 160.286 4.39068 160.286 4.2626C160.286 4.11999 160.194 4.06184 159.998 4.06184H159.876V4.45299ZM160.065 3.80914C160.489 3.80914 160.703 3.95868 160.703 4.23353C160.703 4.42391 160.588 4.56999 160.389 4.62814L160.718 5.27268H160.271L159.995 4.69114H159.876V5.27268H159.47V3.80914H160.065ZM159.275 3.78353C159.057 3.99884 158.943 4.27784 158.943 4.56999C158.943 4.85522 159.049 5.11899 159.256 5.32737C159.467 5.54268 159.743 5.66037 160.037 5.66037C160.326 5.66037 160.603 5.5503 160.817 5.34537C161.027 5.1446 161.141 4.87045 161.141 4.56999C161.141 4.27784 161.035 4.00991 160.832 3.80568C160.618 3.58968 160.345 3.47614 160.042 3.47614C159.743 3.47614 159.482 3.58207 159.275 3.78353ZM161.386 4.56999C161.386 4.93968 161.245 5.27268 160.987 5.51776C160.721 5.77045 160.385 5.90545 160.037 5.90545C159.677 5.90545 159.341 5.76284 159.083 5.49976C158.832 5.24291 158.695 4.91753 158.695 4.56999C158.695 4.21484 158.839 3.87076 159.105 3.61184C159.356 3.36607 159.677 3.23453 160.037 3.23453C160.412 3.23453 160.751 3.37368 161.009 3.63676C161.257 3.88599 161.386 4.21484 161.386 4.56999Z"
        fill="currentColor"
      />
    </svg>
  )
}
