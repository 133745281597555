import { linkExtractor } from '@rentpath/web-utils'
import { Option } from '@swan-io/boxed'

export type PopularCitiesLinks = {
  popularCitiesApartmentLinks: [string, string][]
  popularCitiesHouseLinks: [string, string][]
}

export function getPopularCitiesDataFromContent(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  content: any // this is purposely an `any` as fusion CMS data is not deterministic
): PopularCitiesLinks {
  const popularCitiesApartmentLinks = Option.fromNullable<string>(
    content.popular_cities_apartments_links
  )
    .map(linkExtractor)
    .getWithDefault([])

  const popularCitiesHouseLinks = Option.fromNullable<string>(
    content.popular_cities_houses_links
  )
    .map(linkExtractor)
    .getWithDefault([])

  return {
    popularCitiesApartmentLinks,
    popularCitiesHouseLinks,
  }
}
