'use client'

import clsx from 'clsx'
import errorImage from '@brand/static/images/error.png'
import {
  errorPopularCitiesTitle,
  errorPopularCitiesSubtitle,
  errorPopularCitiesIncludeHouses,
} from '@brand/config/error-page'
import { getSearchHistory } from '@rentpath/web-utils'
import Cookies from 'js-cookie'
import Image from 'next/image'
import { useEffect, useState } from 'react'
import { BrandDivider } from '../../components/brand-divider/brand-divider'
import { Container } from '../../components/container/container'
import { getPopularCitiesDataFromContent } from '../popular-cities/get-popular-cities-data-from-content'
import { PopularCitiesLinks } from '../popular-cities/popular-cities-links/popular-cities-links'
import { SEARCH_HISTORY_COOKIE } from '../search/hooks/search-history.const'
import { SearchComboBox } from '../search/search-combobox/search-combobox'
import styles from './error-page.module.css'
import type { NotFoundPageQuery } from '../not-found/__generated__/not-found.gql'
import { ListingCardPreviewGroup } from '../listing-card-preview-group/listing-card-preview-group'

type Listings = NonNullable<
  NotFoundPageQuery['location']['listingSearch']
>['listings']

interface ErrorPageProps {
  statusCode: number
  header?: string
  message: string
  listings: Listings
  listingsCity?: string
}

type FusionCmsContent = {
  popularCitiesApartmentLinks: [string, string][]
  popularCitiesHouseLinks: [string, string][]
}

export function fetchFusionCmsData(
  setContent: (value: FusionCmsContent) => void
) {
  void fetch('/api/fusion-cms?uri=/')
    .then((response) => {
      if (response.ok) {
        return response.json()
      }

      return null
    })
    .then((data) => {
      if (data) {
        setContent(getPopularCitiesDataFromContent(data))
      }
    })
}

/**
 * `Error` component used for handling errors.
 * https://nextjs.org/docs/advanced-features/custom-error-page
 */
export function ErrorPageUI({
  statusCode,
  header = 'Oops!',
  message,
  listingsCity,
  listings,
}: ErrorPageProps) {
  const [content, setContent] = useState<FusionCmsContent>({
    popularCitiesApartmentLinks: [],
    popularCitiesHouseLinks: [],
  })

  useEffect(() => {
    fetchFusionCmsData(setContent)
  }, [])

  const searchHistoryRaw = Cookies.get(SEARCH_HISTORY_COOKIE) || ''
  const recentSearch = getSearchHistory(searchHistoryRaw)
  const cityTitle =
    listingsCity && listingsCity.length > 0
      ? `Explore other properties in ${listingsCity}`
      : 'Explore other nearby properties'

  return (
    <>
      <Container>
        <section className={styles.errorPageSection}>
          <div className={styles.errorPageImage}>
            <Image src={errorImage} alt="Not Found" width={368} height={426} />
          </div>
          <div className={styles.errorPageHeaderSection}>
            <h1
              className={styles.errorPageHeading}
              data-tid={`${statusCode}-page-header`}
            >
              {header}
            </h1>
            <div className={styles.errorPageDivider} />
            <BrandDivider background="dark" />
            <p
              className={styles.errorPageText}
              data-tid={`${statusCode}-page-subheader`}
            >
              {message}
            </p>

            <SearchComboBox
              searchResultsHeaderClassName={styles.searchResultHeader}
              searchInputClassName={styles.searchInput}
              searchButtonClassName={styles.searchButton}
              resultsWrapperClassName={styles.searchResults}
              className={styles.searchInputWrapper}
              onSelect={(selection, isPropertyPath) => {
                if (isPropertyPath) {
                  window.open(selection.value)
                } else {
                  window.location.assign(selection.value)
                }
              }}
              recentSearch={recentSearch}
            />
          </div>
        </section>
      </Container>

      {listings.length > 0 && (
        <Container
          as="section"
          data-tid="city-search-not-found-listings-section"
          className={styles.section}
        >
          <h2 className={styles.errorPagePopularCitiesHeading}>{cityTitle}</h2>
          <ListingCardPreviewGroup
            listings={listings}
            isHorizontal
            showTourCta
            dataTagSectionPrefix="city_search_not_found"
            className={styles.searchListingCardPreviewGroup}
          />
        </Container>
      )}

      {content.popularCitiesApartmentLinks.length > 0 &&
        content.popularCitiesHouseLinks.length > 0 && (
          <Container
            as="section"
            data-tid="popular-cities-links-section"
            className={styles.errorPageBottomSection}
          >
            <h2 className={styles.errorPagePopularCitiesHeading}>
              {errorPopularCitiesTitle}
            </h2>

            <p className={styles.errorPageText}>{errorPopularCitiesSubtitle}</p>

            <PopularCitiesLinks
              styles={{
                list: clsx(
                  styles.errorPageLinkSectionList,
                  !errorPopularCitiesIncludeHouses &&
                    styles.errorPageLinkSectionListNoHouses
                ),
                link: styles.errorPageLink,
              }}
              apartmentLinks={content.popularCitiesApartmentLinks}
              houseLinks={
                errorPopularCitiesIncludeHouses
                  ? content.popularCitiesHouseLinks
                  : undefined
              }
              collapseLimit={errorPopularCitiesIncludeHouses ? 8 : 16}
            />
          </Container>
        )}
    </>
  )
}
