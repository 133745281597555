import clsx from 'clsx'
import React, { forwardRef } from 'react'
import styles from './brand-divider.module.css'
import type {
  PolymorphicForwardRefExoticComponent,
  PolymorphicPropsWithoutRef,
} from '@rentpath/core-components'
import type { ElementType, ForwardedRef, ReactNode } from 'react'
import type { LightDarkVariant } from '../../types'

type DividerOwnProps = {
  background?: LightDarkVariant
  children?: ReactNode
}

const DividerDefaultElement = 'div'

export const BrandDivider: PolymorphicForwardRefExoticComponent<
  DividerOwnProps,
  typeof DividerDefaultElement
> = forwardRef(function Divider<
  T extends ElementType = typeof DividerDefaultElement
>(
  {
    background,
    as,
    ...restProps
  }: PolymorphicPropsWithoutRef<DividerOwnProps, T>,
  ref: ForwardedRef<Element>
) {
  const Element: ElementType = as || DividerDefaultElement

  return (
    <Element
      ref={ref}
      {...restProps}
      className={clsx(styles.divider, {
        [styles.light]: !background || background === 'light',
        [styles.dark]: background === 'dark',
      })}
    />
  )
})
