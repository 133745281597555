import type {
  PolymorphicForwardRefExoticComponent,
  PolymorphicPropsWithoutRef,
} from '@rentpath/core-components'
import clsx from 'clsx'
import type { ElementType, ForwardedRef, ReactNode } from 'react'
import { forwardRef } from 'react'

import styles from './container.module.css'

export interface ContainerOwnProps {
  children: ReactNode
}

const ContainerDefaultElement = 'div'

export const Container: PolymorphicForwardRefExoticComponent<
  ContainerOwnProps,
  typeof ContainerDefaultElement
> = forwardRef(function Container<
  T extends ElementType = typeof ContainerDefaultElement
>(
  {
    as,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    className,
    ...restProps
  }: PolymorphicPropsWithoutRef<ContainerOwnProps, T>,
  ref: ForwardedRef<Element>
) {
  const Element: ElementType = as || ContainerDefaultElement

  return (
    <Element
      ref={ref}
      className={clsx(styles.container, className)}
      {...restProps}
    />
  )
})
